$(function (DEBUG) {
  $('a[href^="#"]').on('click', function () {
    var $this = $(this),
        href = $this.attr('href'),
        height = STATE.headerHeight,
        position = Number($(href).offset().top - height);

    if (href === '#') {
      $('body,html').stop().animate({
        scrollTop: 0
      }, 500);
    } else {
      $('body,html').stop().animate({
        scrollTop: position
      }, 500);
    }

    return false;
  });

  if (location.hash) {
    $('body,html').stop().scrollTop(0);
  }
});
$(window).on('load', function (DEBUG) {
  if (DEBUG === true) console.log(STATE);

  if (location.hash) {
    setTimeout(function () {
      var target = $(location.hash),
          height = STATE.headerHeight,
          position = Number($(target).offset().top - height);
      $("body,html").stop().animate({
        scrollTop: position
      }, 500);
    }, 1000);
  }
});