var MQ = {
  'pc': 960,
  'tb': 768,
  'sp': 480 //js用のブラウザ幅判定変数

};
STATE.browserMode = ""; //画面幅でクラスを設定する

$(window).on('load resize orientationchange', function (DEBUG) {
  var width = STATE.browserWidth,
      target = $('html');

  if (width == null || isNaN(width)) {
    if (DEBUG) console.log('横幅が取得できませんでした');
    return;
  }

  if (width > MQ.tb) {
    target.addClass('pc').removeClass('tb sp');
    STATE.browserMode = 'pc';
  } else if (width > MQ.sp) {
    target.addClass('tb').removeClass('pc sp');
    STATE.browserMode = 'tb';
  } else {
    target.addClass('sp').removeClass('pc tb');
    STATE.browserMode = 'sp';
  }
});