$(function (DEBUG) {
  STATE.menuFlg = false; //メニューが展開済みかどうかのフラグ

  STATE.beforeScroll = 0; //スクロール保存用変数

  var clickFlg = true,
      //連打を防ぐためのフラグ
  html = $('html'),
      body = $('body'),
      ham = $('.js-ham'),
      hamBorder = $('.js-ham-border'); //spメニューの開閉関数

  function clickMenuEvent() {
    if (clickFlg === true && STATE.menuFlg === false) {
      STATE.menuFlg = true; //アクティブフラグを有効にする

      clickFlg = false; //クリックフラグを無効にする
      //スクロール値が取得できない場合スクロール値を0にする

      if (STATE.browserScroll == null || isNaN(STATE.browserScroll)) {
        STATE.browserScroll = 0;
      }

      STATE.beforeScroll = STATE.browserScroll; //現在のスクロール値を保存

      var tag = '<div id="js-modalback" class="c-modalback"></div>'; //背景用のhtml

      ham.attr('aria-expanded', true);
      html.addClass('js-fixed');
      body.addClass('js-fixed');
      hamBorder.each(function () {
        $(this).addClass('js-active');
      });
      body.prepend(tag); //背景を敷く
    } else if (clickFlg === true && STATE.menuFlg === true) {
      var height = STATE.headerHeight,
          position = Number(STATE.beforeScroll - height);
      STATE.menuFlg = false; //アクティブフラグを無効にする

      clickFlg = false; //クリックフラグを無効にする

      ham.attr('aria-expanded', false);
      html.removeClass('js-fixed');
      body.removeClass('js-fixed');
      $('body,html').scrollTop(position);
      hamBorder.each(function () {
        $(this).removeClass('js-active');
      });
      $('#js-modalback').remove(); //背景の削除
    } //クリックフラグを有効にする


    setTimeout(function () {
      clickFlg = true;
    }, 1000);
  }

  ;
  ham.on('click', function () {
    clickMenuEvent();
  });
  $(document).on('click', '#js-modalback', function () {
    clickMenuEvent();
  }); //メニューアクティブ

  var path = location.pathname;

  if ($('.p-side-list').length) {
    $('.p-side-list').find('.c-side-btn').each(function () {
      var _this = this;

      var link = $(this).attr('href');
      var path2 = path;

      if (path.match('/faculty/previous_2018/')) {
        path2 = path.replace('/faculty/previous_2018/', '/faculty/');

        if (path === link) {
          $(this).addClass('-active');
        }
      } else if (path.match('/faculty/graduate/') && path.match('/curriculum.html')) {
        path2 = path.replace('curriculum.html', '');

        if (path2 === link) {
          $(this).addClass('-active');
        }
      }

      if (path.match('/shijo/course/')) {
        if (path.indexOf('/shijo/course/guide.html') !== 0 && path.indexOf('/shijo/course/amenity.html') !== 0 && path.indexOf('/shijo/course/privacy.html') !== 0 && path.indexOf('/shijo/course/member.html') !== 0 && path.indexOf('/shijo/course/lecture/') !== 0) {
          if (link === '/shijo/course/') {
            $(this).addClass('-active');
          }
        }
      }

      if (path.match('/shijo/course/lecture/')) {
        console.log(path);

        if (link.match('/shijo/course/lecture/')) {
          console.log(link);
          $(this).addClass('-active');
        }
      }

      if (!path2.match(link)) return;

      if (path === link) {
        $(this).addClass('-active');
        return;
      }

      var url = [{
        "/about/philosophy/vision2022/outline/": ["/about/philosophy/vision2022/outline/education.html", "/about/philosophy/vision2022/outline/support.html", "/about/philosophy/vision2022/outline/research.html", "/about/philosophy/vision2022/outline/contribution.html", "/about/philosophy/vision2022/outline/lifelong.html", "/about/philosophy/vision2022/outline/administrative.html"]
      }, {
        "/about/access/murasakino/": ["/about/access/murasakino/map.html"]
      }, {
        "/about/access/nijo/": ["/about/access/nijo/map.html"]
      }, {
        "/about/access/iwakura/": ["/about/access/iwakura/map.html"]
      }, {
        "/about/access/hirosawa/": ["/about/access/hirosawa/map.html"]
      }, {
        "/about/access/sonobe/": ["/about/access/sonobe/map.html"]
      }];
      url.forEach(function (o, n) {
        Object.keys(o).forEach(function (index) {
          o[index].forEach(function (v, i) {
            if (path === v && link === index) $(_this).addClass("-active");
          });
        });
      });
      if (!$(this).next('.js-accordion').length) return;
      $(this).next('.js-accordion').addClass('js-active');
      $(this).nextAll('.js-target').addClass('js-active').attr('aria-hidden', 'true');
    });
  }

  if ($('.c-border-link-list').length) {
    $('.c-border-link-list').find('.c-border-link-list__link').each(function () {
      var link = $(this).attr('href');

      if (path === link) {
        $(this).addClass('js-active');
      }
    });
  }

  if ($('.p-inside-menu__link').length) {
    $('.p-inside-menu__link').each(function () {
      var link = $(this).attr('href');

      if (path === link) {
        $(this).addClass('-active');
      }
    });
  }

  if ($('.p-inside-menu').length) {
    var target = $('.p-inside-menu');

    var topBorder = function topBorder(obj, num) {
      obj.each(function (i) {
        var liNum = $(this).find('li').length;
        var row = Math.ceil(liNum / num);
        $(this).find('li').each(function (i) {
          if (i + 1 <= row * num - num && liNum >= num) {
            if (DEBUG) console.log(i + 1 < row * num - num);
            $(this).addClass('-bottom-border');
          }
        });
      });
    };

    if (target.hasClass('.-four')) {
      topBorder(target, 4);
    } else if (target.hasClass('.-three')) {
      topBorder(target, 3);
    } else if (target.hasClass('.-two')) {
      topBorder(target, 2);
    } else {
      topBorder(target, 5);
    }
  }
});