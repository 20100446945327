//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
import moment from "moment";
export default {
  props: {
    url: String,
    limit: Number,
    year: Number,
    category: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      query: "",
      obj: "",
      count: 0,
      absurl: ''
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.loadData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search === null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setParam: function setParam(data) {
      // propsのyearとcategoryでフィルターをかける
      var result = data;

      if (this.year) {
        result = _.filter(result, {
          'fiscalyear': String(this.year)
        });
      }

      if (this.category) {
        var catlabel = String(this.category);
        result = _.filter(result, function (item) {
          for (var i = 0; i < item.categories.length; i++) {
            return item.categories[i].basename === catlabel;
          }
        });
      }

      return result;
    },
    setCategoryData: function setCategoryData(data) {
      var _this = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.category === "" || this.query.category === void 0 || this.query.category == null) return data;
      var result = [];
      var cat = "";
      data.map(function (val, index, array) {
        cat = val;
        val.categories.map(function (val, index, array) {
          if (String(val.basename) === String(_this.query.category)) result.push(cat);
        });
      });
      return result;
    },
    setBackgroundImg: function setBackgroundImg(img) {
      return 'background-image: url("' + img + '")';
    },
    setPageData: function setPageData(data) {
      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.page === "" || this.query.page === void 0 || this.query.page == null || this.query.page > Math.ceil(data.length / this.limit)) this.query.page = 1;
      var page = Number(this.query.page);
      page = page > 0 ? page : 1;
      var min = page === 1 ? page : (page - 1) * this.limit + 1,
          max = min + this.limit - 1;
      var result = [];
      data.map(function (val, index, array) {
        if (index + 1 >= min && index + 1 <= max) {
          result.push(val);
        }
      });
      return result;
    },
    loadData: function loadData() {
      var _this2 = this;

      try {
        // サンプルURLと本番URL、どちらでも使用できるように修正
        if (this.url.indexOf("://") !== -1) {
          this.absurl = this.url;
        } else {
          this.absurl = "".concat(location.protocol, "//").concat(location.host).concat(this.url);
        }

        axios.get(this.absurl).then(function (res) {
          var items = res.data;
          items = _this2.setCategoryData(items);
          items = _this2.setParam(items);
          _this2.count = items.length;
          items.sort(function (a, b) {
            if (moment(a.date).isAfter(b.date)) return -1;
            if (moment(a.date).isBefore(b.date)) return 1;
            return 0;
          });
          items = _this2.setPageData(items);
          _this2.obj = items;
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};