$(function () {
  $('.c-head-toggle').find('.p-head-space__iconlink').on('click', function () {
    event($(this));
  });
  $('.c-head-toggle').find('.c-lang-btn').on('click', function () {
    event($(this));
  });

  function event(e) {
    var flg = false,
        $this = e.parent();
    if ($this.find('.c-head-toggle__item').hasClass('js-active')) flg = true;
    $('.c-head-toggle__item').each(function () {
      if ($(this).hasClass('js-active')) $(this).removeClass('js-active');
      if ($(this).parents('.p-head-space__iconitem').hasClass('js-active')) $(this).parents('.p-head-space__iconitem').removeClass('js-active');
    });

    if (flg === false) {
      $this.find('.c-head-toggle__item').addClass('js-active');
      $this.addClass('js-active');
    }
  }
});