$(function () {
  $('.l-inside-block__inner').find('a').not('[class]').each(function () {
    var obj = $(this),
        flg = true,
        href = obj.attr('href');
    if (href.match(/.pdf$/i) || href.match(/.doc$/i) || href.match(/.docx$/i) || href.match(/.xls$/i) || href.match(/.xlsx$/i)) flg = false;

    if (flg === false) {
      var size;
      $.ajax({
        url: href,
        type: 'GET'
      }).done(function (data, status, xhr) {
        try {
          var length = xhr.getResponseHeader('Content-Length');
          length = format(length);
          var txt = obj.html() + "（" + length + "）";
          obj.html(txt);
        } catch (e) {}
      }).fail(function (data) {});
    }
  });
  $('.l-inside-block__inner').find('a.c-arrow-link').each(function () {
    var obj = $(this),
        flg = true,
        href = obj.attr('href');
    if (href.match(/.pdf$/i) || href.match(/.doc$	/i) || href.match(/.docx$/i) || href.match(/.xls$/i) || href.match(/.xlsx$/i)) flg = false;

    if (flg === false) {
      var size;
      $.ajax({
        url: href,
        type: 'GET'
      }).done(function (data, status, xhr) {
        try {
          var length = xhr.getResponseHeader('Content-Length');
          length = format(length);
          var txt = obj.children().html() + "（" + length + "）";
          obj.children().html(txt);
        } catch (e) {}
      }).fail(function (data) {});
    }
  });
  $('.l-inside-block__inner').find('a').not('[class]').not(':has("img")').each(function () {
    $(this).wrapInner('<div class="c-link" />');
  });
  $('.l-inside-block__inner').find('a').not('[class]').find("img").each(function () {
    $(this).wrapInner('<div class="c-link-img" />');
  });
  $('.c-border-link-list__link').each(function () {
    var obj = $(this),
        flg = true,
        cont = $(this).find('.c-border-link-list__icon'),
        href = obj.attr('href');
    if (href.match(/.pdf$/i) || href.match(/.doc$	/i) || href.match(/.docx$/i) || href.match(/.xls$/i) || href.match(/.xlsx$/i) || href.match(/.wma$/i)) flg = false;
    if (!cont.length) return;

    if (flg === false) {
      var size;
      $.ajax({
        url: href,
        type: 'GET'
      }).done(function (data, status, xhr) {
        try {
          var length = xhr.getResponseHeader('Content-Length');
          length = format(length);
          var txt = cont.html() + "<span class='c-mini-txt'>（" + length + "）</span>";
          cont.html(txt);
        } catch (e) {}
      }).fail(function (data) {});
    }
  });
});

function format(size) {
  var output = '';

  if (size <= 0) {
    output = size;
  } else if (size < 1024) {
    output = size + 'B';
  } else if (size < 1024 * 1024) {
    output = Math.round(size / 1024 * 100) / 100 + ' KB';
  } else if (size < 1024 * 1024 * 1024) {
    output = Math.round(size / (1024 * 1204) * 100) / 100 + ' MB';
  } else {
    output = Math.round(size / (1024 * 1024 * 1024) * 100) / 100 + ' GB';
  }

  return output;
}