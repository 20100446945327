//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Shijo from "./shijo";
import Pager from "./pager";
import moment from 'moment';
export default {
  components: {
    Shijo: Shijo,
    Pager: Pager
  },
  props: {
    limit: Number,
    url: String,
    endpoint: String
  },
  data: function data() {
    return {
      obj: {},
      setdata: {
        num: null
      }
    };
  },
  methods: {
    setIcon: function setIcon(obj) {
      var result;

      switch (obj) {
        case "jizenmoushikomi":
          result = 'c-course-icon -ic01';
          break;

        case "tokubetsukikaku":
          result = 'c-course-icon -ic02';
          break;

        case "teikyokouza":
          result = 'c-course-icon -ic03';
          break;

        case "syakai":
          result = 'c-course-icon -ic04';
          break;

        case "bukkyo":
          result = 'c-course-icon -ic05';
          break;

        case "kyoyo-kyoto":
          result = 'c-course-icon -ic06';
          break;

        case "kyoyo":
          result = 'c-course-icon -ic07';
          break;

        case "kyoyo-culture":
          result = 'c-course-icon -ic08';
          break;

        case "tsudou":
          result = 'c-course-icon -ic09';
          break;

        case "event":
          result = 'c-course-icon -ic10';
          break;

        case "circle":
          result = 'c-course-icon -ic11';
          break;

        default:
          result = "";
      }

      ;
      return result;
    },
    formatDay: function formatDay(obj) {
      moment.locale('ja');
      if (obj.start_date === "") return null;else {
        var s = moment(obj.start_date, 'YYYY-MM-DD-d').format('YYYY年M月D日(dd)');

        if (obj.end_date === "") {
          return s;
        } else {
          var e = moment(obj.end_date, 'YYYY-MM-DD-d').format('YYYY年M月D日(dd)');
          return s + ' 〜 ' + e;
        }
      }
    },
    formatTime: function formatTime(obj) {
      if (obj.start_time === "") return null;else {
        var s = moment(obj.start_time, 'HH:mm').format('HH:mm');

        if (obj.end_time === "") {
          return s + ' 〜 ';
        } else {
          var e = moment(obj.end_time, 'HH:mm').format('HH:mm');
          return s + ' 〜 ' + e;
        }
      }
    },
    setCount: function setCount(payload) {
      this.$set(this.setdata, 'num', Number(payload));
    }
  },
  computed: {
    reversedObj: function reversedObj() {
      return this.obj;
    }
  }
};