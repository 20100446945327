$(window).on('load resize orientationchange', function (DEBUG) {
  if (STATE.browserMode === 'pc') {
    $('.p-people-box').each(function () {
      var self = $(this);
      var width = Number(self.find('.js-box-position__img').innerWidth());
      self.find('.js-punctuation').css('padding-right', width + 50 + 'px');
      self.find('.js-box').css('padding-right', width + 50 + 'px');
    });
  } else {
    $('.p-people-box').each(function () {
      var self = $(this);
      self.find('.js-punctuation').removeAttr('style');
      self.find('.js-box').removeAttr('style');
    });
  }
});