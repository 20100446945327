$(window).on('load resize orientationchange', function () {
  setTimeout(function () {
    RemoveHeight('.p-event-box__ttl');
    RemoveHeight('.p-event-box-category');
    RemoveHeight('.c-category-list');
    $('.p-event-box__ttl').height(MaxHeigth('.p-event-box__ttl'));
    $('.p-event-box-category').height(MaxHeigth('.p-event-box-category'));
    $('.c-category-list').height(MaxHeigth('.c-category-list'));
  }, 100);
});

function RemoveHeight(target) {
  $(target).each(function (i) {
    $(this).removeAttr('style');
  });
}

function MaxHeigth(target) {
  var maxHeight = 0;
  $(target).each(function (i) {
    var height = $(this).height();
    if (height > maxHeight) maxHeight = height;
  });
  return maxHeight;
}