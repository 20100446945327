$(function (DEBUG) {
  $('.js-back-img').each(function () {
    var $this = $(this),
        target = $this.find('img'),
        img = target.attr('src');
    target.hide();
    $this.css({
      'background-image': 'url("' + img + '")'
    });
  });
});