//状態オブジェクトを作成
STATE = {}; //ブラウザの幅、高さ

STATE.browserWidth = "";
STATE.browserHeight = ""; //縦、横判定

STATE.orientation = ""; //スクロール値

STATE.browserScroll = 0; //ユーザーエージェント

STATE.userAgent = ""; //ヘッダーの高さ

STATE.headerHeight = ""; //フッターの高さ

STATE.footerHeight = "";
$(window).on('load resize orientationchange', function () {
  var browserWidth = $(window).outerWidth(),
      browserHeight = $(window).outerHeight(),
      headerHeight = $('.js-header').innerHeight(),
      footerHeight = $('.js-footer').innerHeight();
  STATE.browserWidth = Number(browserWidth);
  STATE.browserHeight = Number(browserHeight);
  STATE.headerHeight = Number(headerHeight);
  STATE.footerHeight = Number(footerHeight);
});
$(window).on('load orientationchange', function () {
  var orientation = window.orientation;

  switch (orientation) {
    case undefined:
      var decision = 'pc';
      break;

    case 0:
      var decision = 'vertical';
      break;

    default:
      var decision = 'side';
  }

  STATE.orientation = decision;
});
$(window).on('scroll', function () {
  var browserScroll = $(document).scrollTop();
  STATE.browserScroll = Number(browserScroll);
});
$(function () {
  var userAgent = window.navigator.userAgent,
      userAgentChange = userAgent.toLowerCase();
  STATE.userAgent = String(userAgentChange);
});