//前のヘッダー分の高さ
STATE.beforeHeadPadding = ""; //前のフッター分の高さ

STATE.beforeFootPadding = ""; //ヘッダー分のpaddingを付与

function layoutHeadAction(DEBUG) {
  var headPadding = Number($('.js-head-link').innerHeight());

  if (headPadding == null || isNaN(headPadding)) {
    if (DEBUG) console.log('ヘッダーの高さが取得できませんでした');
    return;
  }

  if (STATE.beforeHeadPadding !== headPadding) {
    $('.js-layout').css({
      'padding-top': headPadding + 'px'
    });
    STATE.beforeHeadPadding = headPadding;
  }
}

$(function () {
  var path = location.pathname;
  $('.p-bottom-space__nav').find('.p-bottom-space__item').each(function () {
    var link = $(this).children('a').attr('href');
    if (path === '/') return;
    if (!path.match(link)) return;
    $(this).children('a').addClass('js-active');
    $(this).children('.p-head-hover-area').find('a').each(function () {
      var link = $(this).attr('href');
      if (path !== link) return;
      $(this).addClass('js-active');
    });
  });
}); //フッター分のpaddingを付与

function layoutFootAction(DEBUG) {
  var footPadding = STATE.footerHeight,
      mode = STATE.browserMode;

  if (footPadding == null || isNaN(footPadding)) {
    if (DEBUG) console.log('フッターの高さが取得できませんでした');
    return;
  }

  if (mode == null) {
    if (DEBUG) console.log('ブラウザモードが取得できませんでした');
    return;
  }

  if (STATE.browserMode != 'pc') {
    $('.js-layout').css({
      'padding-bottom': '0'
    });
    STATE.beforeFootPadding = 0;
  } else if (STATE.beforeFootPadding !== footPadding) {
    $('.js-layout').css({
      'padding-bottom': footPadding + 'px'
    });
    STATE.beforeFootPadding = footPadding;
  }
}

$(window).on('load', function (DEBUG) {
  layoutHeadAction(DEBUG);
  layoutFootAction(DEBUG);
});
$(window).on('resize orientationchange', function (DEBUG) {
  setTimeout(function (DEBUG) {
    layoutHeadAction(DEBUG);
    layoutFootAction(DEBUG);
  }, 10);
}); //ヘッダーの横スクロール対応

$(window).on('scroll', function (DEBUG) {
  var x = window.scrollX || window.pageXOffset;
  var scrollLeft = STATE.browserMode === 'pc' ? -1 * Number(window.scrollX || window.pageXOffset) : 0;
  $('.js-header').css({
    'left': scrollLeft + 'px'
  });
});