//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ShijoToday from "./shijo-today";
import moment from 'moment';
export default {
  components: {
    ShijoToday: ShijoToday
  },
  props: {
    url: String
  },
  data: function data() {
    return {
      today: [],
      obj: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.todaySet(); // console.log(this.obj);

    setTimeout(function () {
      _this.obj = _this.returnItem(_this.obj);
    }, 1000); // console.log(this.obj);
  },
  computed: {
    reversedObj: function reversedObj() {
      return this.obj;
    }
  },
  methods: {
    todaySet: function todaySet() {
      this.today.push(moment().year());
      this.today.push(moment().month() + 1);
      this.today.push(moment().date());
    },
    returnItem: function returnItem(item) {
      // console.log(item);
      var result = [],
          today = moment().format('YYYY-MM-DD');
      item.map(function (val, index, array) {
        var startday = moment(val.start_date, 'YYYY-MM-DD-d').format('YYYY-MM-DD');
        var endday = val.end_date.length === -1 ? moment(val.start_date, 'YYYY-MM-DD-d').format('YYYY-MM-DD') : moment(val.end_date, 'YYYY-MM-DD-d').format('YYYY-MM-DD');

        if (moment(today).isSame(startday, 'day') || moment(today).isBetween(startday, endday)) {
          result.push(val);
        }
      }); // console.log(result);

      return result;
    }
  }
};