function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import vuejsDatepicker from 'vuejs-datepicker';
export default {
  components: {
    vuejsDatepicker: vuejsDatepicker
  },
  data: function data() {
    return {
      defaultDate: '',
      DatePickerFormat: 'yyyy年',
      language: _defineProperty({
        language: 'Japanese',
        yearSuffix: '年',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        rtl: false,
        ymd: false
      }, "yearSuffix", '年'),
      value: {
        year: '',
        month: '',
        day: ''
      },
      flg: false
    };
  },
  methods: {
    setValue: function setValue() {
      var val = this.$el.querySelector('.c-form-birth__left input').value;
      console.log('1');

      if (val !== void 0 && val !== null && val !== '') {
        if (val.match('年') && val.match('月') && val.match('日')) {
          console.log('a');
          var val01 = val.split("年");
          var val02 = val01[1].split("月");
          this.value.year = val01[0];
          this.value.month = val02[0] + '月';
          this.value.day = val02[1];
          this.defaultDate = this.value.year;
          var options01 = this.$el.querySelector('.c-form-birth__center select').options;

          for (var i = 0; i < options01.length; i++) {
            if (options01[i].text === this.value.month) {
              options01[i].selected = true;
              break;
            }

            ;
          }

          ;
          var options02 = this.$el.querySelector('.c-form-birth__right select').options;

          for (var x = 0; x < options02.length; x++) {
            if (options02[x].text === this.value.day) {
              options02[x].selected = true;
              break;
            }

            ;
          }

          ;
        } else if (val.match('年') && val.match('月')) {
          console.log('2');

          var _val = val.split('年');

          this.value.year = _val[0];
          this.value.month = _val[1] + '月';
          this.defaultDate = this.value.year;
          var _options = this.$el.querySelector('.c-form-birth__center select').options;

          for (var _i = 0; _i < _options.length; _i++) {
            if (_options[_i].text === this.value.month) {
              _options[_i].selected = true;
              break;
            }

            ;
          }

          ;
        } else if (val.match('年') && val.match('日')) {
          console.log('3');

          var _val2 = val.split('年');

          this.value.year = _val2[0];
          this.value.day = _val2[1];
          this.defaultDate = this.value.year;
          var _options2 = this.$el.querySelector('.c-form-birth__right select').options;

          for (var _x = 0; _x < _options2.length; _x++) {
            if (_options2[_x].text === this.value.day) {
              _options2[_x].selected = true;
              break;
            }

            ;
          }

          ;
          ;
        } else if (val.match('月') && val.match('日')) {
          console.log('4');

          var _val3 = val.split('月');

          this.value.month = _val3[0] + '月';
          this.value.day = _val3[1];
          var _options3 = this.$el.querySelector('.c-form-birth__center select').options;

          for (var _i2 = 0; _i2 < _options3.length; _i2++) {
            if (_options3[_i2].text === this.value.month) {
              _options3[_i2].selected = true;
              break;
            }

            ;
          }

          ;
          var _options4 = this.$el.querySelector('.c-form-birth__right select').options;

          for (var _x2 = 0; _x2 < _options4.length; _x2++) {
            if (_options4[_x2].text === this.value.day) {
              _options4[_x2].selected = true;
              break;
            }

            ;
          }

          ;
        } else if (val.match('年')) {
          var _val4 = val.split('年');

          this.value.year = _val4[0];
          this.value.day = _val4[1];
          this.defaultDate = this.value.year;
        } else if (val.match('月')) {
          this.value.month = val;
          var _options5 = this.$el.querySelector('.c-form-birth__center select').options;

          for (var _i3 = 0; _i3 < _options5.length; _i3++) {
            if (_options5[_i3].text === this.value.month) {
              console.log('5');
              _options5[_i3].selected = true;
              break;
            }

            ;
          }

          ;
        } else if (val.match('日')) {
          console.log('6');
          this.value.day = val;
          var _options6 = this.$el.querySelector('.c-form-birth__right select').options;

          for (var _x3 = 0; _x3 < _options6.length; _x3++) {
            if (_options6[_x3].text === this.value.day) {
              _options6[_x3].selected = true;
              break;
            }

            ;
          }

          ;
        }
      }
    },
    postValue: function postValue() {
      // this.value.month = this.$el.querySelector('.c-form-birth__center select').value;
      // this.value.day = this.$el.querySelector('.c-form-birth__right select').value;
      this.$el.querySelector('.c-form-birth__left input').value = this.value.year + this.value.month + this.value.day;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.setValue();
    this.$children.forEach(function (child) {
      child.$on('selectItem', _this.itemSelected);
    });
  },
  watch: {
    defaultDate: {
      handler: function handler(val, oldVal) {
        var date = new Date(val);
        this.value.year = date.getFullYear() + '年';
        this.postValue();
      }
    },
    value: {
      handler: function handler(val, oldVal) {
        this.postValue();
      },
      deep: true
    }
  }
};