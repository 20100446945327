//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
export default {
  props: {
    text: String,
    link: String,
    url: String,
    year: ''
  },
  data: function data() {
    return {
      data: [],
      // url: `https://api.myjson.com/bins/1here5`,
      //https://cms.bukkyo-u.ekzm.net/library/json/openingday-list.json
      weeks: ['日', '月', '火', '水', '木', '金', '土'],
      calender: [],
      month: '',
      stable: [false, false, false, false, false, false, false, false, false, false, false, false],
      absurl: ''
    };
  },
  mounted: function mounted() {
    this.getMonth();
    this.createCalender();
    this.loadData();
    this.classJudge();
  },
  methods: {
    getMonth: function getMonth() {
      var date = new Date();
      this.month = date.getMonth() + 1;
    },
    prevClick: function prevClick(m) {
      var _this = this;

      this.stable.forEach(function (value, index) {
        if (Number(m) === 1) {
          if (Number(index) === 11) {
            _this.$set(_this.stable, 11, true);
          } else {
            _this.$set(_this.stable, Number(index), false);
          }
        } else {
          if (Number(index) + 2 === Number(m)) {
            _this.$set(_this.stable, Number(index), true);
          } else {
            _this.$set(_this.stable, Number(index), false);
          }
        }
      });
    },
    nextClick: function nextClick(m) {
      var _this2 = this;

      this.stable.forEach(function (value, index) {
        if (Number(m) === 12) {
          if (Number(index) === 0) {
            _this2.$set(_this2.stable, 0, true);
          } else {
            _this2.$set(_this2.stable, Number(index), false);
          }
        } else {
          if (Number(index) === Number(m)) {
            _this2.$set(_this2.stable, Number(index), true);
          } else {
            _this2.$set(_this2.stable, Number(index), false);
          }
        }
      });
    },
    classJudge: function classJudge() {
      var _this3 = this;

      this.stable.forEach(function (value, index) {
        if (_this3.month === index + 1) {
          _this3.$set(_this3.stable, index, true);
        } else {
          _this3.$set(_this3.stable, index, false);
        }
      });
    },
    createCalender: function createCalender() {
      var date = new Date(),
          month = date.getMonth(); // let year = (month < 3)? date.getFullYear() - 1 : date.getFullYear(),

      var year = '',
          data = {};

      if (typeof this.year !== "undefined" && this.year !== null && this.year !== '') {
        year = month < 3 ? this.year - 1 : this.year;
      } else {
        year = month < 3 ? date.getFullYear() - 1 : date.getFullYear();
      } // console.log(year);


      for (var y = 0; y < 12; y++) {
        if (y < 3) {
          createData(year + 1, y + 1);
        } else {
          createData(year, y + 1);
        }
      }

      this.data = data;

      function createData(year, val) {
        var month = val,
            startDate = new Date(year, month - 1, 1),
            endDate = new Date(year, month, 0, 1),
            endDayCount = endDate.getDate() - startDate.getDate() + 1,
            startDay = startDate.getDay();
        var col = 0;

        for (var i = 0; 7 * i < startDay + endDayCount; i++) {
          col = i + 1;
        }

        if (!data[year]) {
          data[String(year)] = {};
        }

        data[year][String(val)] = {};

        for (var x = 1; x <= col * 7; x++) {
          var num = x - 1;
          data[year][val][String(num)] = {};
          data[year][val][num]['col'] = col;
          data[year][val][num]['start'] = startDay;
          data[year][val][num]['all'] = endDayCount;

          if (x > startDay && num < Number(endDayCount) + Number(startDay)) {
            data[year][val][num]['date'] = x - startDay;
            data[year][val][num]['class'] = '-white';
          } else {
            data[year][val][num]['date'] = 'null';
          }
        }
      }
    },
    loadData: function loadData() {
      var _this4 = this;

      // サンプルURLと本番URL、どちらでも使用できるように修正
      if (this.url.indexOf("://") !== -1) {
        this.absurl = this.url;
      } else {
        this.absurl = "".concat(location.protocol, "//").concat(location.host).concat(this.url);
      }

      try {
        axios.get(this.absurl).then(function (res) {
          var data = res.data;
          var copyData = JSON.parse(JSON.stringify(_this4.data));
          var result = data.map(function (val) {
            var classname = "-white",
                open = new Date(val.open),
                startDay = val.open.split('-'),
                close = val.close.length !== 0 ? new Date(val.close) : "null",
                closeDay = val.close.length !== 0 ? val.close.split('-') : "null",
                termDay = close !== "null" ? Math.ceil((close - open) / 86400000) + 1 : 1;
            var col = startDay[2] + termDay;

            if (startDay[1].slice(0, 1) === '0') {
              startDay[1] = startDay[1].slice(1);
            }

            if (startDay[2].slice(0, 1) === '0') {
              startDay[2] = startDay[2].slice(1);
            }

            if (closeDay !== null && closeDay[1].slice(0, 1) === '0') {
              closeDay[1] = closeDay[1].slice(1);
            }

            if (_this4.url.indexOf("library") !== -1) {
              switch (val.status) {
                case "9:00～20:00":
                  classname = "-orange";
                  break;

                case "9:00～17:00":
                  classname = "-yellow";
                  break;

                case "9:00～21:00":
                  classname = "-blue";
                  break;

                case "8:30～17:00":
                  classname = "-pink";
                  break;

                case "8:30～21:00":
                  classname = "-green";
                  break;

                case "10:00～17:00":
                  classname = "-pink02";
                  break;

                case "休館日":
                  classname = "-red";
                  break;

                default:
                  classname = "-white";
                  break;
              }

              ;
            } else if (_this4.url.indexOf("plaza") !== -1) {
              switch (val.status) {
                case "9:00～20:00":
                  classname = "-orange";
                  break;

                case "9:00～17:00":
                  classname = "-yellow";
                  break;

                case "9:00～12:30":
                  classname = "-blue";
                  break;

                case "9:00～18:00":
                  classname = "-pink";
                  break;

                case "9:00～19:00":
                  classname = "-green";
                  break;

                case "9:00～21:00":
                  classname = "-pink02";
                  break;

                case "閉室日":
                  classname = "-red";
                  break;

                default:
                  classname = "-white";
                  break;
              }

              ;
            }

            Object.keys(copyData).forEach(function (key) {
              // 配列の各要素に対する処理
              if (Number(key) === Number(startDay[0])) {
                var cont = copyData[key];
                Object.keys(cont).forEach(function (index) {
                  if (Number(index) === Number(startDay[1])) {
                    var items = cont[index];
                    Object.keys(items).forEach(function (a) {
                      var item = items[a];

                      if (Number(item.date) === Number(startDay[2])) {
                        if (termDay === 0) {
                          substitution(item);
                        } else {
                          var residue = Number(termDay - 1) - Number(item.all - startDay[2]); // 月をまたいだ時

                          if (residue > 0) {
                            var remnant = Number(termDay);
                            var remnantMonth = Number(closeDay[1] - startDay[1]); // 例：12月〜2月の場合など

                            if (remnantMonth < 0) {
                              remnantMonth += +12;
                            }

                            for (var i = 0; i < 12; i++) {
                              var year = Number(index) + i > 12 ? String(Number(key) + 1) : String(key),
                                  month = Number(index) + i > 12 ? String(Number(index) + i - 12) : String(Number(index) + i),
                                  all = copyData[year][month][0].all,
                                  start = copyData[year][month][0].start;

                              for (var q = 0; q < remnant; q++) {
                                var startcell = q + start + Number(startDay[2]) - 1; // 開始月

                                if (i === 0 && all + start > startcell) {
                                  substitution(copyData[year][month][startcell]);
                                } // 途中の月（期間が3ヶ月またぐ場合のみ）
                                else if (i > 0 && remnantMonth - i !== 0 && q < all) {
                                    substitution(copyData[year][month][q + start]);
                                  } // 最後の月
                                  else if (remnantMonth - i === 0) {
                                      substitution(copyData[year][month][closeDay[2] - (remnant - q) + start]);
                                    }
                              }

                              if (i === 0) {
                                remnant = remnant - (all - Number(startDay[2]) + 1);
                              } else {
                                remnant -= all;
                              }

                              if (remnant < 0 || month === 3) {
                                break;
                              }
                            }

                            ;
                          } else {
                            var _remnant = Number(termDay),
                                _year = String(key),
                                _month = String(index),
                                _all = copyData[_year][_month][0].all,
                                _start = copyData[_year][_month][0].start;

                            for (var _q = 0; _q < _remnant; _q++) {
                              substitution(copyData[_year][_month][_q + _start + Number(startDay[2]) - 1]);
                            }
                          }
                        }
                      }

                      function substitution(target) {
                        target['class'] = classname;
                        target['id'] = val.id;
                        target['title'] = val.title;
                        target['status'] = val.status;
                        target['campus'] = val.campus;
                      }
                    });
                  }
                });
              }
            });
          });
          _this4.data = copyData; // console.log(this.data);
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};