//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    endpoint: String,
    query: String,
    limit: Number,
    results: Number
  },
  data: function data() {
    return {
      pager: [],
      page: 1,
      selected: null,
      range: 2,
      total: 0,
      prev: false,
      next: false,
      counts: {
        all: 0,
        start: 0,
        end: 0
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.page = _this.setPage();
      _this.total = _this.setPagination();

      _this.pageCount();

      _this.setPageObj();
    }, 1000);
  },
  methods: {
    setPage: function setPage() {
      var query = window.location.search;
      var page = 1;

      if (query !== null && query !== "") {
        var hash = query.slice(1).split("&");

        for (var i = 0; i < hash.length; i++) {
          var array = hash[i].split("=");
          if (array[0] === "page") page = Number(array[1]);
        }
      }

      return page;
    },
    setPagination: function setPagination() {
      var count = Math.ceil(this.results / this.limit);
      return Number(count);
    },
    pageCount: function pageCount() {
      var start = Number((this.page - 1) * this.limit + 1),
          end = start + this.limit - 1;
      if (end > this.results) end = this.results;
      this.counts.all = this.results;
      this.counts.start = start;
      this.counts.end = end;
    },
    pageMove: function pageMove(n) {
      var query = window.location.search,
          url = location.href,
          newQuery = "";

      if (query !== null && query !== "") {
        var hash = query.slice(1).split("&");

        for (var i = 0; i < hash.length; i++) {
          var array = hash[i].split("=");
          url = url.replace(hash[i], "");

          if (array[0] !== "page") {
            if (newQuery.slice(-1) !== "&" && newQuery.slice(-1) !== "") newQuery = newQuery + "&";
            newQuery = newQuery + array[0] + "=" + array[1];
          }
        }

        url = url.replace(/&/g, "");

        if (newQuery === "") {
          url = url;
        } else {
          url = url + newQuery + "&";
        }
      } else {
        url = url + "?";
      }

      url = url + "page=" + "".concat(n);
      location.href = url;
    },
    setCurrent: function setCurrent(page) {
      if (page === this.page) {
        return "js-current";
      }
    },
    showNext: function showNext() {
      if (this.page + this.range > this.setPagination() || this.setPagination() < 3) {
        return false;
      } else {
        // console.log(this.setPagination())
        return true;
      }
    },
    showPrev: function showPrev() {
      if (this.page < 2 || this.setPagination() < 3) {
        return false;
      } else {
        return true;
      }
    },
    showPager: function showPager() {
      if (this.counts.all !== this.counts.start) {
        return true;
      } else {
        return false;
      }
    },
    setPageObj: function setPageObj() {
      var items = [];
      var self = this;

      for (var i = 0; i < self.total; i++) {
        var page = {
          content: i + 1,
          disable: false,
          show: false
        };
        items[i] = page;
      }

      var pagePrevLink = {
        content: "…",
        disable: "disabled",
        show: false
      };
      var pageNextLink = {
        content: "…",
        disable: "disabled",
        show: false
      };

      for (var _i = 0; _i < items.length; _i++) {
        if (self.setPagination() === self.page) {
          if (_i === self.page - 1 || _i === self.page - self.range / 2 - 1) {
            items[_i].show = true;
          }
        } else {
          if (_i === self.page - 1 || _i === self.page + self.range / 2 - 1) {
            items[_i].show = true;
          }
        }

        if (_i === self.page - 1 || _i === self.page && self.total > self.page && self.page > 1) {
          items[_i].show = true;

          if (self.range !== self.page) {
            items[0].show = true;
          }

          if (self.page !== self.total - self.range) {
            items[self.total - 1].show = true;
          }
        }
      }

      if (self.page > self.range) {
        if (self.range == 1 && self.total > 2) {
          pagePrevLink.show = true;
        } else if (self.range == 2 && self.page == 2) {
          pagePrevLink.show = false;
        } else if (self.range !== 1) {
          pagePrevLink.show = true;
        }
      }

      if (self.total - self.range >= self.range && self.total - self.range > self.page) {
        pageNextLink.show = true;
      }

      items.splice(1, 0, pagePrevLink);
      items.splice(items.length - 1, 0, pageNextLink);
      this.pager = items;
    }
  }
};