$(function (DEBUG) {
  $('.js-slick').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000
  });
  $('.js-slick-mv').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000
  });
  var num01 = $('.js-slick-center').find('.p-slick-slide-one__item').length;

  if (num01 < 4) {
    var def = 4 - num01;

    for (var x = 0; x < def; x++) {
      $('.js-slick-center').append($('.js-slick-center').children().clone());
    }
  }

  $('.js-slick-center').slick({
    arrows: false,
    dots: true,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '20%',
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [{
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        centerPadding: '20%'
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '10%'
      }
    }]
  });
  var num02 = $('.js-slick-center-four').find('.p-slick-slide-two__item').length;

  if (num02 < 5) {} else {
    $('.js-slick-center-four').slick({
      arrows: false,
      dots: true,
      slidesToShow: 4,
      // centerMode: true,
      // centerPadding: '10%',
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1 // centerPadding: '10%',

        }
      }]
    });
  } // $('.js-slick-center-four').slick({
  //   arrows: false,
  //   dots: true,
  //   slidesToShow: 4,
  //   centerMode: true,
  //   centerPadding: '10%',
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerPadding: '10%',
  //       }
  //     }
  //   ]
  // });


  $('.js-slick-bottom').slick({
    arrows: false,
    dots: true,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '10%'
      }
    }]
  });
});