//
//
import axios from 'axios';
import moment from 'moment';
export default {
  props: {
    url: String,
    limit: ''
  },
  data: function data() {
    return {
      query: "",
      shijoCourse: [{
        class: "ic01",
        label: "事前申込制講座",
        basename: "jizenmoushikomi"
      }, {
        class: "ic02",
        label: "特別企画",
        basename: "tokubetsukikaku"
      }, {
        class: "ic03",
        label: "提供講座",
        basename: "teikyokouza"
      }, {
        class: "ic04",
        label: "現代社会を生きる",
        basename: "syakai"
      }, {
        class: "ic05",
        label: "仏教を極める",
        basename: "bukkyo"
      }, {
        class: "ic06",
        label: "教養を深める＜京都＞",
        basename: "kyoyo-kyoto"
      }, {
        class: "ic07",
        label: "教養を深める＜歴史・民俗・伝統＞",
        basename: "kyoyo"
      }, {
        class: "ic08",
        label: "教養を深める＜文学・芸術・自然科学＞",
        basename: "kyoyo-culture"
      }, {
        class: "ic08",
        label: "教養を深める＜文学・芸術＞",
        basename: "kyoyo-culture"
      }, {
        class: "ic09",
        label: "つどう・ふれあう",
        basename: "tsudou"
      }, {
        class: "ic10",
        label: "イベント",
        basename: "event"
      }, {
        class: "ic11",
        label: "サークル",
        basename: "circle"
      }]
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.filteredData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search == null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setCource: function setCource() {
      var path = location.pathname,
          array = path.split('/');
      var course = String(array[array.length - 2]);
      var result = "";

      if (_.find(this.shijoCourse, {
        basename: course
      })) {
        result = course;
      } else {
        result = "all";
      }

      return result;
    },
    filteredData: function filteredData() {
      var _this = this;

      try {
        axios.get(this.url).then(function (res) {
          var items = res.data;
          _this.type = _this.setCource();
          items = _this.corseSet(items);
          items = _this.sortData(items);
          items = _this.setPage(items);
          _this.$parent.obj = items;
        });
      } catch (err) {
        alert(err);
      }
    },
    setPage: function setPage(data) {
      if (this.query.page === 'not') return data;
      if (this.limit === 'not') return data;
      var page = this.query.page === '' || this.query.page === null || this.query.page === void 0 || this.query.page > Math.ceil(data.length / this.limit) ? 1 : Number(this.query.page);
      page = page > 0 ? page : 1;
      var min = page === 1 ? page : (page - 1) * this.limit + 1,
          max = min + this.limit - 1;
      var result = [];
      this.$emit("setresults", data.length);
      data.map(function (val, index, array) {
        if (index + 1 >= min && index + 1 <= max) result.push(val);
      });
      return result;
    },
    corseSet: function corseSet(data) {
      var _this2 = this;

      if (this.type === 'all') return data;
      var result = [];
      data.map(function (val) {
        if (val.kind.basename === _this2.type) result.push(val);
      });
      return result;
    },
    sortData: function sortData(data) {
      //時間で並び替え
      var item = data.sort(function (a, b) {
        if (a.end_time !== "" && b.end_time !== "") {
          var p = moment(a.end_time, 'hh:mm'),
              _n = moment(b.end_time, 'hh:mm');

          return sortFnc(p, _n);
        } else {
          return 0;
        }
      });
      item = data.sort(function (a, b) {
        if (a.start_time !== "" !== "" && b.start_time !== "" !== "") {
          var p = moment(a.start_time, 'hh:mm'),
              _n2 = moment(b.start_time, 'hh:mm');

          return sortFnc(p, _n2);
        } else {
          return 0;
        }
      }); //最終日で並び替え

      item = data.sort(function (a, b) {
        if (a.end_date !== "" && b.end_date !== "") {
          var p = moment(a.end_date, 'YYYY-MM-DD-d');
          n = moment(b.end_date, 'YYYY-MM-DD-d');
          return sortFnc(p, n);
        } else {
          return 0;
        }
      }); //開始日で並び替え

      item = data.sort(function (a, b) {
        if (a.start_date !== "" && b.start_date !== "") {
          var p = moment(a.start_date, 'YYYY-MM-DD-d'),
              _n3 = moment(b.start_date, 'YYYY-MM-DD-d');

          return sortFnc(p, _n3);
        } else {
          return 0;
        }
      });

      function sortFnc(obj1, obj2) {
        if (moment(obj1).isAfter(obj2)) return -1;
        if (moment(obj1).isBefore(obj2)) return 1;
        return 0;
      } // シリーズで並び替え（並び順はshijoCourseを参照）


      if (this.type === 'all') {
        var result = [];

        _.map(this.shijoCourse, function (c) {
          result.push(_.filter(item, function (o) {
            return o.kind.basename == c.basename;
          }));
        });

        item = _.flatten(result);
      }

      return item;
    }
  }
};