//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import moment from 'moment';
export default {
  props: {
    url: String,
    limit: Number,
    year: Number
  },
  data: function data() {
    return {
      obj: [],
      page: [],
      count: 0,
      absurl: ''
    };
  },
  mounted: function mounted() {
    this.page = this.setQuery();
    this.filteredData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search == null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setParam: function setParam(data) {
      var result = data;

      if (this.year) {
        result = _.filter(result, {
          'fiscalyear': String(this.year)
        });
      }

      return result;
    },
    setBackgroundImg: function setBackgroundImg(img) {
      return 'background-image: url("' + img + '")';
    },
    filteredData: function filteredData() {
      var _this = this;

      // サンプルURLと本番URL、どちらでも使用できるように修正
      if (this.url.indexOf("://") !== -1) {
        this.absurl = this.url;
      } else {
        this.absurl = "".concat(location.protocol, "//").concat(location.host).concat(this.url);
      }

      try {
        axios.get(this.absurl).then(function (res) {
          var items = res.data;
          _this.count = items.length;
          items = items.sort(function (a, b) {
            var p = moment(a.date, 'YYYY-MM-DD'),
                n = moment(b.date, 'YYYY-MM-DD');
            return sortFnc(p, n);
          });
          items = _this.setParam(items);
          var result = [];

          for (var x = (_this.page.page - 1) * _this.limit; x < _this.limit * _this.page.page; x++) {
            if (items.length <= x) break;
            var item = items[x];
            result.push(item);
          }

          ;
          _this.obj = result;

          function sortFnc(obj1, obj2) {
            if (moment(obj1).isAfter(obj2)) return -1;
            if (moment(obj1).isBefore(obj2)) return 1;
            return 0;
          }
        });
      } catch (err) {
        alert(err);
      }
    },
    setPage: function setPage(data) {
      if (this.page === '' || this.page === null || this.page === void 0) return data;
    }
  }
};