$(function (DEBUG) {
  $('.js-trigger').on('click', function () {
    var trigger = $(this);
    var target = trigger.closest('.js-tab'),
        affected = target.find('.p-tab__box'),
        state = trigger.attr('data-state');
    trigger.parent().find('.js-trigger').removeClass('js-active');
    affected.each(function () {
      var $this = $(this);

      if ($this.attr('data-state') === state) {
        trigger.addClass('js-active');
        $this.addClass('js-active');
      } else {
        $this.removeClass('js-active');
      }
    });
  });
});