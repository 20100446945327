//
//
//
//
//
//
//
//
//
//
import axios from "axios";
export default {
  props: {
    pageId: String,
    limit: Number
  },
  data: function data() {
    return {
      query: "",
      setdata: {
        count: null
      }
    };
  },
  methods: {
    setCount: function setCount(payload) {
      // console.log(payload);
      this.$set(this.setdata, 'count', Number(payload.detail[0]));
    }
  }
};