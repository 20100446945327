$(window).on('load resize orientationchange', function (DEBUG) {
  var mode = STATE.browserMode,
      target = $('.js-head-document-btn');

  if (mode == null) {
    if (DEBUG) console.log('ブラウザモードが取得できませんでした');
    return;
  }

  if (mode === 'pc') {
    var height = STATE.headerHeight;

    if (height == null || isNaN(height)) {
      if (DEBUG) console.log('ヘッダーの高さが取得できませんでした');
      return;
    }

    target.css({
      'height': height + 'px'
    });
  } else {
    target.removeAttr('style');
  }
});