$(window).on('load resize orientationchange', function (DEBUG) {
  if (STATE.browserMode === 'pc') {
    var width = Number($('.js-mv-ttl').outerWidth());
    $('.js-pankuzu').removeAttr('style');
    $('.js-pankuzu').css('padding-left', width + 20 + 'px');
  } else {
    var height = Number($('.js-mv-ttl').outerHeight() / 2);
    $('.js-pankuzu').removeAttr('style');
    $('.js-pankuzu').css('padding-top', height + 10 + 'px');
  }
});