//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
import moment from "moment";
export default {
  props: {
    color: String,
    pageId: String,
    limit: Number
  },
  data: function data() {
    return {
      query: "",
      data: [],
      count: 0,
      // url: "https://api.myjson.com/bins/ytpir"
      url: "//".concat(location.host, "/").concat(this.pageId, "/json/archive-list.json")
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.loadData();
  },
  methods: {
    setColor: function setColor(color) {
      if (color == "white") return null;else return "-beige";
    },
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search == null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setData: function setData(data) {
      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.page === "" || this.query.page === void 0 || this.query.page == null || this.query.page > Math.ceil(data.length / this.limit)) this.query.page = 1;
      var page = Number(this.query.page);
      page = page > 0 ? page : 1;
      var min = page === 1 ? page : (page - 1) * this.limit + 1,
          max = min + this.limit - 1;
      data.sort(function (a, b) {
        var c = a.end === "" || a.end === void 0 || a.end == null ? a.start : a.end.replace(/-/g, ''),
            d = b.end === "" || b.end === void 0 || b.end == null ? b.start : b.end.replace(/-/g, '');
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      });
      data.sort(function (a, b) {
        var c = a.start.replace(/-/g, ''),
            d = b.start.replace(/-/g, '');
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        return 0;
      });
      var result = [];
      var result2 = [];

      if (!this.query.year) {
        var now = moment();
        data.map(function (val, index, array) {
          var date = moment(val.end, 'YYYY-MM-DD');
          date = date === "" ? val.start : date;
          if (moment(date).diff(now, 'days') >= 0) result.push(val);
        });
      } else {
        result = _.orderBy(data, ['start', 'end', 'date'], ['desc', 'asc', 'desc']);
      }

      this.$emit("setresults", result.length);
      result.map(function (val, index, array) {
        if (index + 1 >= min && index + 1 <= max) {
          var obj = JSON.parse(JSON.stringify(val));
          result2.push(obj);
        }
      });
      return result2;
    },
    setCategory: function setCategory(data) {
      var _this = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.category === "" || this.query.category === void 0 || this.query.category == null) return data;
      var result = [];
      var cat = "";
      data.map(function (val, index, array) {
        cat = val;
        val.categories.map(function (val, index, array) {
          if (String(val.basename) === String(_this.query.category)) result.push(cat);
        });
      });
      return result;
    },
    setYear: function setYear(data) {
      var _this2 = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.year === "" || this.query.year === void 0 || this.query.year == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        if (_this2.query.year === val.fiscalyear) result.push(val);
      });
      return result;
    },
    // setYear(data){
    //   if (data === "" || data === void 0 || data == null) return data;
    //   if(this.query.year === ""  || this.query.year === void 0 || this.query.year == null) return data;
    //   let result = [];
    //   data.map((val, key, index) => {
    //     let start = "",
    //         end = "";
    //     if(typeof val.start !== 'underfined' && val.start !== null && val.start !== ""){
    //       start = val.start.split('-');
    //       start = Number(start[0]);
    //     }
    //     if(typeof val.end !== 'underfined' && val.end !== null && val.end !== ""){
    //       end = val.end.split('-');
    //       end = Number(end[0]);
    //     }
    //     const period = [];
    //     if(typeof start !== "" && end!== ""){
    //       for(let x = start; start <= end; start++) {
    //         period.push(x);
    //       }
    //     }else if(start !== ""){
    //       period.push(start);
    //     }else{
    //       period.push(Number(val.year));
    //     }
    //     if(period.indexOf(Number(this.query.year)) >= 0) result.push(val);
    //   });
    //   return result;
    // },
    setMonth: function setMonth(data) {
      var _this3 = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.month === "" || this.query.month === void 0 || this.query.month == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        var start = "",
            start2 = "",
            end = "",
            end2 = "";

        if (typeof val.start !== 'underfined' && val.start !== null && val.start !== "") {
          start = val.start.split('-');
          start2 = Number(start[1].slice(0, 1)) === 0 ? Number(start[1].slice(1)) : Number(start[1]);
        }

        if (typeof val.end !== 'underfined' && val.end !== null && val.end !== "") {
          end = val.end.split('-');
          end2 = Number(end[1].slice(0, 1)) === 0 ? Number(end[1].slice(1)) : Number(end[1]);
        }

        var period = [];

        if (start2 !== "" && end2 !== "") {
          var date1 = new Date(Number(start[0]), Number(start[1])),
              date2 = new Date(Number(end[0]), Number(end[1]));

          for (var x = date1; date1 <= date2;) {
            period.push(x.getMonth());
            x.setMonth(x.getMonth() + 1);
          }
        } else if (start2 !== "") {
          period.push(start2);
        } else {
          var num = Number(val.month.slice(0, 1)) === 0 ? Number(val.month.slice(1).slice(0, -2)) : Number(val.month.slice(0, -2));
          period.push(num);
        }

        if (period.indexOf(Number(_this3.query.month)) >= 0) result.push(val);
      });
      return result;
    },
    loadData: function loadData() {
      var _this4 = this;

      try {
        axios.get(this.url).then(function (res) {
          var items = _this4.setCategory(res.data); // カテゴリーは使用しないけどナビ表示で形だけ必要


          items = _this4.setYear(items);
          items = _this4.setMonth(items);
          items = _this4.setData(items);
          _this4.data = items;
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};