//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
import moment from "moment";
export default {
  props: {
    pageId: String,
    limit: Number
  },
  data: function data() {
    return {
      query: "",
      data: [],
      // url: "https://api.myjson.com/bins/ytpir"
      url: "//".concat(location.host, "/").concat(this.pageId, "/json/archive-list.json")
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.loadData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search == null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setTarget: function setTarget(type) {
      if (type == "blank") return "_blank";
    },
    setParmalink: function setParmalink(data) {
      // パーマリンクについて、null or 外部リンク or 添付ファイルかでtargetのタイプを分ける（linktypeを追加）
      var result = [];
      data.map(function (val, index, array) {
        var obj = val;

        if (obj.permalink !== null) {
          obj.linktarget = "normal";

          if (obj.permalink.indexOf('://') !== -1 || obj.permalink.indexOf('.html') !== -1) {
            obj.linktarget = "blank";
          }
        } else {
          obj.linktarget = "null";
        }

        result.push(obj);
      });
      return result;
    },
    setData: function setData(data) {
      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.page === "" || this.query.page === void 0 || this.query.page == null || this.query.page > Math.ceil(data.length / this.limit)) this.query.page = 1;
      var page = Number(this.query.page);
      page = page > 0 ? page : 1;
      var min = page === 1 ? page : (page - 1) * this.limit + 1,
          max = min + this.limit - 1;
      data.sort(function (a, b) {
        var c = a.end === "" || a.end === void 0 || a.end == null ? a.start : a.end.replace(/-/g, ''),
            d = b.end === "" || b.end === void 0 || b.end == null ? b.start : b.end.replace(/-/g, '');
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      });
      data.sort(function (a, b) {
        var c = a.start.replace(/-/g, ''),
            d = b.start.replace(/-/g, '');
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        return 0;
      });
      var result = [];
      var result2 = [];

      if (!this.query.year) {
        var now = moment();
        data.map(function (val, index, array) {
          var date = moment(val.end, 'YYYY-MM-DD');
          date = date === "" ? val.start : date;
          if (moment(date).diff(now, 'days') >= 0) result.push(val);
        });
      } else {
        result = _.orderBy(data, ['start', 'end', 'date'], ['desc', 'asc', 'desc']);
      }

      this.$emit("setresults", result.length);
      result.map(function (val, index, array) {
        if (index + 1 >= min && index + 1 <= max) {
          var obj = JSON.parse(JSON.stringify(val));
          result2.push(obj);
        }
      });
      return result2;
    },
    setCategory: function setCategory(data) {
      if (data === "" || data === void 0 || data == null) return data;
      var result = [];
      var cat = "";
      data.map(function (val, index, array) {
        cat = val;
        val.categories.map(function (val, index, array) {
          // 該当するカテゴリに絞り込み
          if (String(val.basename) === "symposium") result.push(cat);
        });
      });
      return result;
    },
    loadData: function loadData() {
      var _this = this;

      try {
        axios.get(this.url).then(function (res) {
          var items = _this.setCategory(res.data); // カテゴリーは使用しないけどナビ表示で形だけ必要


          items = _this.setParmalink(items);
          items = _this.setData(items);
          _this.data = items;
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};