"use strict"; //functionに引数でdebugを読み込ませる debugがtrueであればエラーの確認コードを走らせる

var DEBUG = false;
import "@babel/polyfill";
import "./foundation/base.js"; import "./foundation/mq.js"; import "./foundation/ua.js";
import "./layout/layout.js";
import "./component/border-accent-ttl.js"; import "./component/border-double-ttl.js"; import "./component/calendar-slide.js"; import "./component/head-document-btn.js"; import "./component/head-toggle.js"; import "./component/menu.js"; import "./component/move-menu.js"; import "./component/pankuzu.js"; import "./component/people-box.js"; import "./component/slick.js"; import "./component/tab.js";
import "./utility/accordion.js"; import "./utility/anchor.js"; import "./utility/back-img.js"; import "./utility/date.js"; import "./utility/external.js"; import "./utility/height.js"; import "./utility/img-link.js"; import "./utility/img.js"; import "./utility/size.js"; import "./utility/table.js"; import "./utility/tel.js";
import _ from "lodash";
import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import "document-register-element";
Vue.use(vueCustomElement); // component

import VuePager from "../vue/component/pager.vue";
Vue.customElement("v-pager", VuePager);
import FourBox from "../vue/component/four-box.vue";
Vue.customElement("v-four-box", FourBox);
import LineTable from "../vue/component/line-table.vue";
Vue.customElement("v-line-table", LineTable);
import ArchiveNav from "../vue/component/archive-nav.vue";
Vue.customElement("v-archive-nav", ArchiveNav);
import ArchiveNavBottom from "../vue/component/archive-nav-bottom.vue";
Vue.customElement("v-archive-nav-bottom", ArchiveNavBottom);
import Calendar from "../vue/component/calendar.vue";
Vue.customElement("v-calendar", Calendar);
import PlazaCalendar from "../vue/component/plaza-calendar.vue";
Vue.customElement("v-plaza-calendar", PlazaCalendar);
import Event from "../vue/component/event.vue";
Vue.customElement("v-event", Event);
import EventLabo from "../vue/component/event-labo.vue";
Vue.customElement("v-event-labo", EventLabo);
import Birthday from "../vue/component/birthday.vue";
Vue.customElement("v-birthday", Birthday);
import Shijo from "../vue/component/shijo.vue";
Vue.customElement("v-shijo", Shijo);
import Course from "../vue/component/course.vue";
Vue.customElement("v-course", Course);
import TodayCourse from "../vue/component/today-course.vue";
Vue.customElement("v-today-course", TodayCourse);
import Report from "../vue/component/report.vue";
Vue.customElement("v-report", Report);
import Publications from "../vue/component/publications.vue";
Vue.customElement("v-publications", Publications);
import Student from "../vue/component/student.vue";
Vue.customElement("v-student", Student);
import EducationReport from "../vue/component/education-report.vue";
Vue.customElement("v-education-report", EducationReport);
import ExchangeEvent from "../vue/component/exchange-event.vue";
Vue.customElement("v-exchange-event", ExchangeEvent);
import Voice from "../vue/component/voice.vue";
Vue.customElement("v-voice", Voice);
import Rss from "../vue/component/rss.vue";
Vue.customElement("v-rss", Rss); // archive

import ArchiveNews from "../vue/archive/archive-news.vue";
Vue.customElement("v-archive-news", ArchiveNews);
import ArchiveImportant from "../vue/archive/archive-important.vue";
Vue.customElement("v-archive-important", ArchiveImportant);
import ArchiveEvent from "../vue/archive/archive-event.vue";
Vue.customElement("v-archive-event", ArchiveEvent); //pager

import EventPager from "../vue/pager/event-pager.vue";
Vue.customElement("v-event-pager", EventPager);
import NewPager from "../vue/component/new-pager.vue";
Vue.customElement("v-new-pager", NewPager);

if (DEBUG === true) {
  //グローバル変数:lowerCamelCaseを使う。ただしグローバルオブジェクトは全て大文字。
  //定数:すべて大文字で、単語毎にアンダースコアで区切る。
  //関数:lowerCamelCaseを使う。
  //ローカル変数:lowerCamelCaseを使う。
  //ファイル名は区切り文字はハイフン（-）のみ使用する。全て小文字。
  console.log("app.js loaded");
  console.log(STATE);
}