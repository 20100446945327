$(function (DEBUG) {
  var ua = STATE.userAgent;
  if (DEBUG === true) console.log(STATE);

  if (ua == null) {
    if (DEBUG === true) console.log('ユーザーエージェントが取得できませんでした');
    return;
  }

  var ERROR = {
    'ua': 'ブラウザの判定ができませんでした',
    'os': 'OS、機種の判定ができませんでした'
  }; //判定処理関数

  function processing(val, obj) {
    var target = obj === undefined ? $('html') : target;

    switch (val) {
      case 'browser-false':
        if (DEBUG) console.log(ERROR.ua);
        break;

      case 'os-false':
        if (DEBUG) console.log(ERROR.os);
        break;

      case 'ie11':
        target.addClass('ie');
        break;

      default:
        target.addClass(val);
    }
  } //ブラウザの判定


  ua.indexOf('edge') != -1 ? processing('edge') : ua.indexOf('rv:11.0') != -1 ? processing('ie11') : ua.indexOf('chrome') != -1 ? processing('chrome') : ua.indexOf('safari') != -1 ? processing('safari') : ua.indexOf('firefox') != -1 ? processing('firefox') : ua.indexOf('opera') != -1 ? processing('opera') : processing('browser-false'); //OS、機種の判定

  ua.match(/win(dows )?nt 10\.0/) ? processing('win10') : ua.match(/win(dows )?nt 6\.1/) ? processing('win7') : ua.indexOf('mac') !== -1 && ua.indexOf('iphone') == -1 ? processing('mac') : ua.indexOf('iphone') != -1 ? processing('iphone') : ua.indexOf('android') != -1 ? processing('android') : processing('os-false');
});