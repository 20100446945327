$(function (DEBUG) {
  var ua = STATE.userAgent,
      target = $('.js-tel-link');

  if (ua == null) {
    if (DEBUG) console.log('ユーザーエージェントが取得できませんでした');
    return;
  } //スマホならリンクをつける


  if (ua.match(/(iphone|ipad|ipod|android)/)) target.each(function () {
    var str = String($(this).text()),
        tag = '<a href="tel:' + str.replace(/-/g, '') + '">' + str + '</a>';
    $(this).html(tag);
  });
});