//
//
import axios from 'axios';
import moment from 'moment';
export default {
  props: {
    url: String
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.filteredData();
  },
  methods: {
    filteredData: function filteredData() {
      var _this = this;

      try {
        axios.get(this.url).then(function (res) {
          var items = res.data;
          items = _this.setPage(items);
          items = _this.sortData(items);
          _this.$parent.obj = items;
        });
      } catch (err) {
        alert(err);
      }
    },
    setPage: function setPage(data) {
      if (this.page === 'not') return data;
      var page = this.page === '' || this.page === null || this.page === void 0 ? 1 : this.page;
      var result = [];
      var now = moment();
      data.map(function (val, index) {
        val.entries.map(function (entry, index) {
          var date = moment(entry.start_date, 'YYYY-MM-DD-d');

          if (moment(date).diff(now, 'days') == 0) {
            // 後のsortをしやすくするために、データ整形したものをpush
            result.push({
              "title": "".concat(val.title, " - ").concat(entry.title),
              "permalink": val.permalink,
              "start_date": entry.start_date,
              "start_time": entry.start_time,
              "end_date": entry.end_date,
              "end_time": entry.end_time
            });
          }
        });
      }); // console.log(result);

      return result;
    },
    sortData: function sortData(data) {
      //時間で並び替え
      var item = data.sort(function (a, b) {
        var p = moment(a.start_time, 'hh:mm'),
            n = moment(b.start_time, 'hh:mm');
        return sortFnc(p, n);
      });

      function sortFnc(obj1, obj2) {
        if (moment(obj1).isAfter(obj2)) return 1;
        if (moment(obj1).isBefore(obj2)) return -1;
        return 0;
      }

      return item;
    }
  }
};