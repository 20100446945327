$(function (DEBUG) {
  function accordionActive(obj) {
    var mode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pc';
    var $this = obj,
        parent = obj.parent(),
        target = $this.next('.js-target');

    if (target.hasClass('js-active')) {
      parent.attr('aria-expanded', 'false');
      obj.removeClass('js-active');
      target.attr('aria-hidden', 'false').removeClass('js-active');
      if (mode !== 'pc') parent.attr('data-accordion', 'false');

      if (parent.find('.js-accordion').length) {
        parent.find('.js-accordion').each(function () {
          if ($(this).hasClass('js-active')) {
            $(this).parent().attr('aria-expanded', 'false');
            $(this).next('.js-target').removeClass('js-active');
            $(this).attr('aria-hidden', 'false').removeClass('js-active');
            if (mode !== 'pc') $(this).parent().attr('data-accordion', 'false');
          }
        });
      }
    } else {
      parent.attr('aria-expanded', 'true');
      obj.addClass('js-active');
      target.attr('aria-hidden', 'true').addClass('js-active');
      if (mode !== 'pc') parent.attr('data-accordion', 'true');
    }
  }

  $('.js-accordion').on('click', function () {
    var _this = this;

    accordionActive($(this));
    if (!$(this).hasClass('p-side-list__icon')) return;
    if (!$(this).next().find('.js-accordion').length) return;
    setTimeout(function () {
      $(_this).next().find('.js-accordion').each(function (i) {
        var height = $(this).prev().innerHeight() - 20;
        $(this).css({
          'height': height
        });
      });
    }, 300);
  });
  $('.js-sp-accordion').on('click', function () {
    accordionActive($(this), 'sp');
  });
});
$(window).on('load resize orientationchange', function (DEBUG) {
  var mode = STATE.browserMode;

  if (mode == null) {
    if (DEBUG) console.log('ブラウザモードが取得できませんでした');
    return;
  }

  if (mode != 'pc') {
    $('.js-sp-accordion').each(function (i) {
      var $this = $(this);

      if ($this.attr('aria-expanded') == null) {
        var parent = $this.parent(),
            target = $this.next('.js-target'),
            state = parent.attr('data-accordion');

        if (state == null) {
          parent.attr({
            'aria-expanded': 'false',
            'data-accordion': 'false'
          });
          target.attr('aria-hidden', 'false');
        } else {
          parent.attr('aria-expanded', state);
          target.attr('aria-hidden', state);
        }
      }

      $('.p-side-list__icon.js-accordion').each(function (i) {
        var _this2 = this;

        setTimeout(function () {
          var height = $(_this2).prev().innerHeight() - 20;
          $(_this2).css({
            'height': height
          });
        }, 1000);
      });
    });
  } else {
    $('.js-sp-accordion').each(function (i) {
      var $this = $(this),
          parent = $this.parent(),
          target = $this.next('.js-target');
      parent.removeAttr('aria-expanded');
      target.removeAttr('aria-hidden');
    });
    $('.p-side-list__icon.js-accordion').each(function (i) {
      var _this3 = this;

      setTimeout(function () {
        var height = $(_this3).prev().innerHeight() - 20;
        $(_this3).css({
          'height': height
        });
      }, 1000);
    });
  }
});