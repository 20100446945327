$(function (DEBUG) {
  //外部リンク自動付与
  $('a[href^=http]').not('[href*="' + location.hostname + '"]').attr({
    target: "_blank",
    rel: "noopener noreferrer"
  });
  $('a[href$=".pdf"]').attr({
    target: "_blank",
    rel: "noopener noreferrer"
  });
  $('a[href$=".xls"]').attr({
    target: "_blank",
    rel: "noopener noreferrer"
  });
  $('a[href$=".doc"]').attr({
    target: "_blank",
    rel: "noopener noreferrer"
  });
});