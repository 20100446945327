//
//
//
//
//
//
import axios from "axios";
export default {
  props: {
    limit: Number
  },
  data: function data() {
    return {
      query: "",
      data: [],
      count: 0,
      url: "https://api.myjson.com/bins/13zzu9",
      path: "/event/"
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.loadData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search == null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    categorySeparation: function categorySeparation(data) {
      if (data === "" || data === void 0 || data == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        var category = val.category.split(',');
        val.category = category;
        result.push(val);
      });
      return result;
    },
    setCategory: function setCategory(data) {
      var _this = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.category === "" || this.query.category === void 0 || this.query.category == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        var category = val.category.split(',');
        if (category.indexOf(String(_this.query.category)) >= 0) result.push(val);
      });
      return result;
    },
    setYear: function setYear(data) {
      var _this2 = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.year === "" || this.query.year === void 0 || this.query.year == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        var open = "",
            open2 = "",
            close = "",
            close2 = "";

        if (typeof val.open !== 'underfined' && val.open !== null && val.open !== "") {
          open = val.open.split('-');
          open2 = Number(open[1].slice(0, 1)) === 0 ? Number(open[1].slice(1)) : Number(open[1]);
          open = Number(open[0]);
          if (open2 < 4) open -= open;
        }

        if (typeof val.close !== 'underfined' && val.close !== null && val.close !== "") {
          close = val.close.split('-');
          close2 = Number(close[1].slice(0, 1)) === 0 ? Number(close[1].slice(1)) : Number(close[1]);
          close = Number(close[0]);
          close = Number(open[0]);
          if (close2 < 4) close -= close;
        }

        var period = [];

        if (typeof open !== "" && close !== "") {
          for (var x = open; open <= close; open++) {
            period.push(x);
          }
        } else if (open !== "") {
          period.push(open);
        } else {
          period.push(Number(val.year));
        }

        if (period.indexOf(Number(_this2.query.year)) >= 0) result.push(val);
      });
      return result;
    },
    setMonth: function setMonth(data) {
      var _this3 = this;

      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.month === "" || this.query.month === void 0 || this.query.month == null) return data;
      var result = [];
      data.map(function (val, key, index) {
        var open = "",
            open2 = "",
            close = "",
            close2 = "";

        if (typeof val.open !== 'underfined' && val.open !== null && val.open !== "") {
          open = val.open.split('-');
          open2 = Number(open[1].slice(0, 1)) === 0 ? Number(open[1].slice(1)) : Number(open[1]);
        }

        if (typeof val.close !== 'underfined' && val.close !== null && val.close !== "") {
          close = val.close.split('-');
          close2 = Number(close[1].slice(0, 1)) === 0 ? Number(close[1].slice(1)) : Number(close[1]);
        }

        var period = [];

        if (open2 !== "" && close2 !== "") {
          var date1 = new Date(Number(open[0]), Number(open[1])),
              date2 = new Date(Number(close[0]), Number(close[1]));

          for (var x = date1; date1 <= date2;) {
            period.push(x.getMonth());
            x.setMonth(x.getMonth() + 1);
          }
        } else if (open2 !== "") {
          period.push(open2);
        } else {
          var num = Number(val.month.slice(0, 1)) === 0 ? Number(val.month.slice(1).slice(0, -2)) : Number(val.month.slice(0, -2));
          period.push(num);
        }

        if (period.indexOf(Number(_this3.query.month)) >= 0) result.push(val);
      });
      return result;
    },
    loadData: function loadData() {
      var _this4 = this;

      try {
        axios.get(this.url).then(function (res) {
          var items = _this4.setCategory(res.data);

          items = _this4.setYear(items);
          items = _this4.setMonth(items);
          items = _this4.categorySeparation(items);
          _this4.count = items.length;
          _this4.data = items;
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};