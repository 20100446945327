$(function (DEBUG) {
  var clickFlg = true;
  $('.js-move-menu-btn').on('click', function () {
    if (clickFlg === true) {
      clickFlg = false;
      var self = $(this),
          type = self.attr('data-type'),
          flg = self.attr('data-flg');
      self.closest('.js-move-menu').find('.js-move-menu-btn').each(function () {
        $(this).removeClass('js-active');
      });
      if (flg === "false") self.addClass('js-active');
      self.closest('.js-move-menu').find('.js-move-menu-cont').each(function () {
        $this = $(this);
        $this.removeClass('js-active');

        if ($this.attr('data-type') === type && flg === "false") {
          $this.addClass('js-active');
        }
      });
      self.closest('.js-move-menu').find('.js-move-menu-btn').each(function () {
        $(this).attr('data-flg', 'false');
      });

      if (flg === "false") {
        self.attr('data-flg', 'true');
      }

      setTimeout(function () {
        clickFlg = true;
      }, 500);
    }
  });
});