//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import queryString from 'query-string';
export default {
  props: ['all', 'range', 'limits', 'url'],
  data: function data() {
    return {
      pageNum: 1,
      pageAll: 0,
      pageList: [],
      pagerRange: this.range - 2,
      query: {},
      beforeOmitted: false,
      afterOmitted: false,
      debugFlg: true
    };
  },
  created: function created() {
    this.query = queryString.parse(location.search);
    var loadPageNum = this.query.page;

    if (typeof loadPageNum !== 'undefined') {
      this.pageNum = Number(this.query.page);
    }

    this.query.page = this.pageNum;
  },
  mounted: function mounted() {
    this.debug({
      'ページ番号': this.pageNum
    });
    this.loadRange();
    this.setRange();
    console.log(this.limits);
    console.log(this.all);
    console.log(this.pageAll);
  },
  methods: {
    debug: function debug(e) {
      if (this.debugFlg === true) {
        console.log(e);
      }

      return e;
    },
    activeClass: function activeClass(e) {
      if (this.pageNum === e) {
        return 'js-current';
      }
    },
    beforeJump: function beforeJump() {
      var copyQuery = Object.assign({}, this.query);
      copyQuery.page = Number(this.query.page) - 1;
      var count = 0,
          createUrl = '';

      for (var _i = 0, _Object$keys = Object.keys(copyQuery); _i < _Object$keys.length; _i++) {
        var val = _Object$keys[_i];
        if (count !== 0) createUrl = createUrl + '&';
        createUrl = createUrl + val + '=' + copyQuery[val];
        count++;
      }

      return this.url + '?' + createUrl;
    },
    afterJump: function afterJump() {
      var copyQuery = Object.assign({}, this.query);
      copyQuery.page = Number(this.query.page) + 1;
      var count = 0,
          createUrl = '';

      for (var _i2 = 0, _Object$keys2 = Object.keys(copyQuery); _i2 < _Object$keys2.length; _i2++) {
        var val = _Object$keys2[_i2];
        if (count !== 0) createUrl = createUrl + '&';
        createUrl = createUrl + val + '=' + copyQuery[val];
        count++;
      }

      return this.url + '?' + createUrl;
    },
    beforeOmittedShow: function beforeOmittedShow(e) {
      if (this.pageList.indexOf(e) === -1 && this.pageList.indexOf(e + 1) === -1) {
        return true;
      } else {
        return false;
      }
    },
    afterOmittedShow: function afterOmittedShow(e) {
      if (this.pageList.indexOf(e) === -1 && this.pageList.indexOf(e - 1) === -1) {
        return true;
      } else {
        return false;
      }
    },
    setUrl: function setUrl(e) {
      var copyQuery = Object.assign({}, this.query);
      copyQuery.page = e;
      var count = 0,
          createUrl = '';

      for (var _i3 = 0, _Object$keys3 = Object.keys(copyQuery); _i3 < _Object$keys3.length; _i3++) {
        var val = _Object$keys3[_i3];
        if (count !== 0) createUrl = createUrl + '&';
        createUrl = createUrl + val + '=' + copyQuery[val];
        count++;
      }

      return this.url + '?' + createUrl;
    },
    showItem: function showItem(e) {
      if (this.pageList.indexOf(e) >= 0 && e > 1 && e < this.pageAll) {
        return true;
      } else {
        return false;
      }
    },
    loadRange: function loadRange() {
      this.pageAll = Math.ceil(this.all / this.limits);
      this.debug({
        '範囲': this.pageAll
      });
      return this.pageAll;
    },
    setRange: function setRange() {
      var halfRange = Math.floor(this.pagerRange / 2);
      var rangeArray = [];
      var minNum = 1;

      if (this.pageNum > halfRange - 1 && this.pageAll > this.pageNum + halfRange - 1) {
        minNum = this.pageNum - halfRange + 1;
        this.beforeOmitted = true;
        this.afterOmitted = true;
      } else if (this.pageNum > halfRange + 1 && this.pageAll - halfRange < this.pageNum) {
        minNum = this.pageAll - this.pagerRange + 1;
        this.beforeOmitted = true;
      } else if (this.pageNum - halfRange + 1 > this.pageNum) {
        minNum = this.pageNum - halfRange + 1;
        this.afterOmitted = true;
      }

      if (this.pageAll < this.range) {
        minNum = 1;
        this.pagerRange = this.pageAll;
      }

      for (var i = 0; i < this.pagerRange; i++) {
        if (minNum === 1 && this.pageAll > this.range) {
          rangeArray.push(this.range - 1);
        } else if (minNum === this.pageAll) {
          rangeArray.push(this.pageAll - this.range);
        }

        rangeArray.push(minNum + i);
      }

      this.pageList = rangeArray;
      return this.pageList;
    }
  }
};