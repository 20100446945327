//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
import moment from "moment";
export default {
  props: {
    url: String,
    limit: Number,
    endpoint: String
  },
  data: function data() {
    return {
      query: "",
      obj: "",
      count: 0,
      absurl: ''
    };
  },
  mounted: function mounted() {
    this.query = this.setQuery();
    this.loadData();
  },
  methods: {
    setQuery: function setQuery() {
      if (window.location.search === "" || window.location.search === void 0 || window.location.search === null) return {
        page: 1
      };
      var variables = window.location.search.split("?")[1].split("&"),
          obj = {};
      variables.forEach(function (v, i) {
        var variable = v.split("=");
        obj[variable[0]] = variable[1];
      });
      return obj;
    },
    setFormat: function setFormat(date) {
      return moment(date, 'YYYY-MM-DD').format('YYYY.MM.DD');
    },
    setBackgroundImg: function setBackgroundImg(img) {
      return 'background-image: url("' + img + '")';
    },
    setPageData: function setPageData(data) {
      if (data === "" || data === void 0 || data == null) return data;
      if (this.query.page === "" || this.query.page === void 0 || this.query.page == null || this.query.page > Math.ceil(data.length / this.limit)) this.query.page = 1;
      var page = Number(this.query.page);
      page = page > 0 ? page : 1;
      var min = page === 1 ? page : (page - 1) * this.limit + 1,
          max = min + this.limit - 1;
      var result = [];
      data.map(function (val, index, array) {
        if (index + 1 >= min && index + 1 <= max) {
          result.push(val);
        }
      });
      return result;
    },
    loadData: function loadData() {
      var _this = this;

      // サンプルURLと本番URL、どちらでも使用できるように修正
      if (this.url.indexOf("://") !== -1) {
        this.absurl = this.url;
      } else {
        this.absurl = "".concat(location.protocol, "//").concat(location.host).concat(this.url);
      }

      try {
        axios.get(this.absurl).then(function (res) {
          var items = res.data;
          _this.count = items.length;
          items.sort(function (a, b) {
            if (moment(a.date).isAfter(b.date)) return -1;
            if (moment(a.date).isBefore(b.date)) return 1;
            return 0;
          });
          items = _this.setPageData(items);
          _this.obj = items;
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};