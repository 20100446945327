$(function (DEBUG) {
  var clickFlg = true;
  $('.js-calendar-left').on('click', function () {
    if (clickFlg === true) {
      clickFlg = false;
      var $this = $(this),
          target = $this.closest('.js-calendar-item'),
          prev = $this.closest('.js-calendar-item').prev();

      if (prev.length) {
        target.removeClass('js-active');
        prev.addClass('js-active');
      }
    }

    setTimeout(function () {
      clickFlg = true;
    }, 1000);
  });
  $('.js-calendar-right').on('click', function () {
    if (clickFlg === true) {
      clickFlg = false;
      var $this = $(this),
          target = $this.closest('.js-calendar-item'),
          next = $this.closest('.js-calendar-item').next();

      if (next.length) {
        target.removeClass('js-active');
        next.addClass('js-active');
      }
    }

    setTimeout(function () {
      clickFlg = true;
    }, 1000);
  });
});